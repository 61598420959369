import React, {createElement} from 'react';
import {CustomSection} from '../styles/CustomPageStyles';
import localize from '../components/localize';
import { graphql } from 'gatsby'

export default localize(PrivacyPolicy);

function PrivacyPolicy({ data, pageContext }) {
  const regulationContent = data.sanityPrivacyPolicy._rawDescription[0].children;
  return (
    <CustomSection>
      <h1>{data.sanityPrivacyPolicy.title[pageContext.locale]}</h1>
      <div>
        {
          regulationContent &&
          regulationContent.map((item, index) => (
            createElement(item._type, {key: index}, item.text)
          ))
        }
      </div>
    </CustomSection>
  );
}

export const query = graphql`
  query PrivacyPolicyQuery {
    sanityPrivacyPolicy{
      title {
        en
        fr
      }
      _rawDescription
    }
  }
`;